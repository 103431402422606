import { ApplicationRef, ChangeDetectorRef, Component, TemplateRef, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS, MatSnackBar } from '@angular/material/snack-bar';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { Subscription, shareReplay, of, interval, filter, map } from 'rxjs';
import { environment } from 'src/environments/environment';
import { InternetConnectionService } from '../../../ag-core';
import { MaterialModule } from '../../../material.module';
import { ConfirmationPopupComponent } from '../../../ag-shared/popup/confirmation-popup/confirmation-popup.component';
import Swal from 'sweetalert2';
import { AppUpdatePopupComponent } from '../../../ag-shared/popup/app-update-popup/app-update-popup.component';

@Component({
  selector: 'app-ag-utils',
  standalone: true,
  imports: [CommonModule, MaterialModule, ConfirmationPopupComponent],
  templateUrl: './ag-utils.component.html',
  styleUrls: ['./ag-utils.component.scss']
})
export class AgUtilsComponent {
  
  private unsubscribe: Subscription[] = [];
  private isUpdateCall: boolean = false;
  @ViewChild('templateAppUpdateLogs') templateAppUpdateLogs: TemplateRef<any>;
  isConfirmAppUpdate: boolean = false;
  appUpdateDetails: any[] = [];
  // const isBrowserTabInView = () => document.hidden;
  constructor(
    private swUpdate: SwUpdate,
    private _snackBar : MatSnackBar,
    private internet : InternetConnectionService,
    private appRef: ApplicationRef,
    private cdr: ChangeDetectorRef,
    private http: HttpClient,
    private bottomSheet: MatBottomSheet,
    private dialog : MatDialog
  ) {
    console.log('<------------------------- Ag Utils ------------------------------------->');
  }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this.checkInternetConnection();
    this.appUpdate();
    //this.appAutoUpdate();
    //this.checkUpdate();
    if (environment.production) {
      document.addEventListener('contextmenu', event => event.preventDefault());
    }
  }

  ngOnDestroy() {
		this.unsubscribe.forEach(sb => sb.unsubscribe());
  }


  getCallMethod(serviceCallName) {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'x-auth-token': JSON.parse(localStorage.getItem('authToken') || ''),
        'x-ag-date': new Date().toISOString(),
				'x-app-version': environment.appVersion
      })
    };
		const url: string = environment.REST_API_BASE_URL + '/' + serviceCallName;
		if (environment.isMockEnabled) {
			return this.http.get<any>(url, httpOptions).pipe(shareReplay(1));
		} else {
			return of({status: false});
		}
  }

    // appAutoUpdate() {
  //   // if (this.swUpdate.isEnabled) {
  //   //   this.swUpdate.available.subscribe(event => {
  //   //     this.handleUpdateAvailable();
  //   //   });
  //   // }
  //   if (this.swUpdate.isEnabled) {
  //     this.swUpdate.checkForUpdate();
  //     this.swUpdate.available.subscribe(event => {
  //       this.swUpdate.activateUpdate().then(() => {
  //         // Reload the page to load the new version
  //         window.location.reload();
  //       });
  //     });
  //     // this.swUpdate.versionUpdates.subscribe((evt) => {
  //     //   switch (evt.type) {
  //     //     case 'VERSION_READY':
  //     //       console.log("SW Version Ready");
  //     //       this.swUpdate.activateUpdate().then(() => {
  //     //         document.location.reload();
  //     //       }).catch(err => {
  //     //         //this._snackBar.open('SW Update Error', '', { duration : 0 });
  //     //       });
  //     //       break;
  //     //   }
  //     // });
  //   }
  // }

    // checkUpdate() {
  //   this.appRef.isStable.subscribe((isStable) => {
  //     if (isStable) {
  //       // 8 Hours 
  //       const timeInterval = interval(8 * 60 * 60 * 1000);
        
  //       timeInterval.subscribe(() => {
  //         this.swUpdate.checkForUpdate().then(() => {
  //           console.log('App Update Checked');
  //         });
  //       });
  //     }
  //   });
  // }

  // openAppUpdateSheet() {
	// 	if (!this.swUpdate.isEnabled) {
	// 		return;
  //   }
  //   this.swUpdate.checkForUpdate();
	// 	const updateSubscription = this.swUpdate.available.subscribe(event => {
	// 		if (!this.isUpdateCall) {	
	// 			this.isUpdateCall = true;
  //       const confirmDialogRef = this.dialog.open(ConfirmationPopupComponent, {
  //         data: { 
  //           description: 'New Update Available! Are you sure want to reload this page', 
  //           title : 'Confirmation!', 
  //           okBtn : 'Ok', 
  //           cancelBtn : 'Cancel'
  //         },
  //         width: '400px',
  //         hasBackdrop: true,
  //         disableClose: true
  //       });
	// 			const confirmSubs = confirmDialogRef.afterClosed().subscribe((confirm: any) => {
  //         if (confirm) {
  //           this.swUpdate.activateUpdate().then(() => {
  //             window.location.reload();
  //           });
  //         } else {
  //           this._snackBar.open('New Update Available! Please Reload this page.', 'Reload', {duration: 0})
  //           .afterDismissed()
  //           .subscribe(
  //             (res) => {
  //               this.swUpdate.activateUpdate().then(() => {
  //                 window.location.reload();
  //               });
  //             }
  //           );
  //         }
  //       });
  //       this.unsubscribe.push(confirmSubs);
	// 		}
	// 	});
  //   this.unsubscribe.push(updateSubscription);
    
  //   const updateActivatedSubscription = this.swUpdate.activated.subscribe((activated) => {
  //     this._snackBar.open('App Updated Successfully.', 'Ok', {duration: 2000});
  //   });
  //   this.unsubscribe.push(updateActivatedSubscription);
  // }

  


  // handleUpdateAvailable() {
  //   // Automatically activate the new version
  //   this.swUpdate.activateUpdate().then(() => {
  //     // Reload the page to load the new version
  //     window.location.reload();
  //   });
  // }

  // closeAppUpdateSheet(confirmation) {
  //   this.isConfirmAppUpdate = confirmation;
  //   this.bottomSheet.dismiss();
  //   if (this.isConfirmAppUpdate) {
  //     this.isConfirmAppUpdate = false;
  //     this.swUpdate.activateUpdate().then((activated) => {
  //       if (activated) {
  //         this._snackBar.open('App Updated Successfully.', 'Ok', {duration: 2000});
  //         setTimeout(() => {
  //           window.location.reload();
  //         }, 2000);
  //       } else {
  //         this._snackBar.open('App already on the latest version.', 'Ok', {duration: 2000});
  //       }
  //     });
  //   } else {
  //     this._snackBar.open('New Update Available! Please Reload this page.', 'Reload', {duration: 0}).afterDismissed().subscribe((res) => {
  //       this.swUpdate.activateUpdate().then((activated) => {
  //         if (activated) {
  //           this._snackBar.open('App Updated Successfully.', 'Ok', {duration: 2000});
  //           setTimeout(() => {
  //             window.location.reload();
  //           }, 2000);
  //         } else {
  //           this._snackBar.open('App already on the latest version.', 'Ok', {duration: 2000});
  //         }
  //       });
  //     });
  //   }
  // }

  appUpdate() {
    if(this.swUpdate.isEnabled) {
      this.swUpdate.versionUpdates.subscribe((event) => {
        console.log('event---------------------------->', event);
        if (event.type === 'VERSION_READY') {
          const dialogRef = this.dialog.open(AppUpdatePopupComponent, {
            width : '500px',
            disableClose : true
          });
          const dialogSubs = dialogRef.afterClosed().subscribe(
            (res : boolean) => {
              if(res) {
                this.swUpdate.activateUpdate().then((activated) => {
                  if(activated) {
                    window.location.reload();
                    console.log('App Updated Successfully');
                  } else {
                      console.log('App Alerady on latest version');
                  }
                });
              }
            }
          );
          this.unsubscribe.push(dialogSubs);
        }
      });
    } else {
        console.log('Service Worker Not Enabled');
    }
  }

  // appUpdate() {
  //   console.log('isEnabled ----------------------------->', this.swUpdate.isEnabled);
	// 	if (!this.swUpdate.isEnabled) {
	// 		return;
  //   }
  //   this.swUpdate.checkForUpdate();
  //   const updateVersionSubs = this.swUpdate.versionUpdates.subscribe(
  //     event => {
  //       // console.log('event------------------------------->', event);
  //       if(event.type == 'VERSION_DETECTED') {
  //         console.log('**********************App Updated Successfully**********************');
  //         window.location.reload();
  //       }
  //     }
  //   );
  //   this.unsubscribe.push(updateVersionSubs);
	// 	// const updateSubscription = this.swUpdate.available.subscribe(event => {
  //   //   console.log('***************** Update Available ***********************');
  //   //   window.location.reload();
	// 	// 	// if (!this.isUpdateCall) {	
	// 	// 	// 	this.isUpdateCall = true;
  //   //   //   const confirmDialogRef = this.dialog.open(ConfirmationComponent, {
  //   //   //     data: { 
  //   //   //       description: 'New Update Available! Are you sure want to reload this page', 
  //   //   //       title : 'Confirmation!', 
  //   //   //       okBtn : 'Update', 
  //   //   //       cancelBtn : 'Cancel'
  //   //   //     },
  //   //   //     width: '400px',
  //   //   //     hasBackdrop: true,
  //   //   //     disableClose: true
  //   //   //   });
	// 	// 	// 	const confirmSubs = confirmDialogRef.afterClosed().subscribe((confirm: any) => {
  //   //   //     if (confirm) {
  //   //   //       this.swUpdate.activateUpdate().then(() => {
  //   //   //         window.location.reload();
  //   //   //       });
  //   //   //     } else {
  //   //   //       this._snackBar.open('New Update Available! Please Reload this page.', 'Reload', {duration: 0})
  //   //   //       .afterDismissed()
  //   //   //       .subscribe(
  //   //   //         (res) => {
  //   //   //           this.swUpdate.activateUpdate().then(() => {
  //   //   //             window.location.reload();
  //   //   //           });
  //   //   //         }
  //   //   //       );
  //   //   //     }
  //   //   //   });
  //   //   //   this.unsubscribe.push(confirmSubs);
	// 	// 	// }
	// 	// });
  //   // this.unsubscribe.push(updateSubscription);
    
  //   const updateActivatedSubscription = this.swUpdate.activated.subscribe((activated) => {
  //     //this._snackBar.open('App Updated Successfully.', 'Ok', {duration: 2000});
  //   });
  //   this.unsubscribe.push(updateActivatedSubscription);
  // }
  

  
  checkInternetConnection() {
		let snackbarAction;
		const internetSubscription = this.internet.isInternetAvailable.subscribe(
			(isInternet) => {
				if (!isInternet) {
					snackbarAction = this._snackBar.open('You Are Offline.', '', { duration : 0 });
				} else {
					if (snackbarAction) {
						snackbarAction.dismiss();
					}
				}
			}
		);
		this.unsubscribe.push(internetSubscription);
		if (!navigator.onLine) {
			snackbarAction = this._snackBar.open('You Are Offline', '', { duration : 0 });
		}
  }

}
