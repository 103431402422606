<div class="update">
    <!-- <div class="circle1"></div>
    <div class="circle2"></div>
    <div class="circle3"></div> -->
    <div>
        <div class="content">
            <h4>YES Connect has been updated to a new version.</h4>
        </div>
        <div>
            <button class="primary-btn mb-4 ms-0" (click)="onSubmit()">
                <span>UPDATE NOW</span>
            </button>
            <button class="secondary-btn ms-0" (click)="close()">
                <span>LATER</span>
            </button>
        </div>
    </div>
</div>