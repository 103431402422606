import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LocalStorageService } from '../../../ag-core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-app-update-popup',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './app-update-popup.component.html',
  styleUrls: ['./app-update-popup.component.scss']
})
export class AppUpdatePopupComponent {

  constructor(
    private storage : LocalStorageService,
    public dialogRef: MatDialogRef<AppUpdatePopupComponent>
  ) {}

  close() {
    this.storage.setItem('update_version_later', true);
    this.dialogRef.close(false);
  }

  onSubmit() {
    this.storage.setItem('update_version_later', false);
    this.dialogRef.close(true);
  }

}
