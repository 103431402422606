const CONSTANTS = {

  subscriptionPlansCategories: [
    {
      id : 'general',
      value : 'General'
    },
    {
      id : 'special',
      value : 'Special'
    }
  ],

  eventStatusData: [
    {
      id : 'Completed',
      value : 'Completed'
    },
    {
      id : 'Cancelled',
      value : 'Cancelled'
    }
  ],
  gst_registration: [
    // {
    //     id : 'regular_tax_payer',
    //     value : 'Regular Tax Payer'
    // },
    // {
    //     id : 'composition_scheme',
    //     value : 'Composition Scheme'
    // },
    // {
    //     id : 'e_commerce_operator',
    //     value : 'E-commerce Operator'
    // },
    // {
    //     id : 'sez',
    //     value : 'SEZ Unit'
    // },
    {
      id : 'unregistered',
      value : 'Unregistered'
    },
    {
      id : 'registered',
      value : 'Registered'
    }
  ],

  reference_objects: {
    LEADS : 'LEADS',
    MEMBERS : 'MEMBERS',
    GUEST: 'GUEST',
    LOGIN: 'LOGIN',
    EMPLOYEES : 'EMPLOYEES',
    EVENT_VOLUNTEERS : 'EVENT_VOLUNTEERS',
    EVENTS : 'EVENTS',
    PAYMENTS : 'PAYMENTS',
    MEMBER_SUBSCRIPTIONS : 'MEMBER_SUBSCRIPTIONS',
    SPOTLIGHTS : 'SPOTLIGHTS',
    FORGOT_PASSWORD : 'FORGOT_PASSWORD',
    CUBELABS: 'CUBELABS',
    CUBELAB_MEETINGS: 'CUBELAB_MEETINGS',
    SPEAKERS: 'SPEAKERS',
    CHANGE_PASSWORD : 'CHANGE_PASSWORD'
  },

  payment_reference_objects : {
    MEMBER_SUBSCRIPTIONS : 'MEMBER_SUBSCRIPTIONS',
    SPOTLIGHTS: 'SPOTLIGHTS',
    EVENTS : 'EVENTS'
  },

  validity_months: [
    {
      id : 'jan',
      value : 'January'
    },
    {
      id : 'feb',
      value : 'February'
    },
    {
      id : 'mar',
      value : 'March'
    },
    {
      id : 'apr',
      value : 'April'
    },
    {
      id : 'may',
      value : 'May'
    },
    {
      id : 'jun',
      value : 'June'
    },
    {
      id : 'jul',
      value : 'July'
    },
    {
      id : 'aug',
      value : 'August'
    },
    {
      id : 'sep',
      value : 'September'
    },
    {
      id : 'oct',
      value : 'October'
    },
    {
      id : 'nov',
      value : 'November'
    },
    {
      id : 'dec',
      value : 'December'
    }
  ],

  foodData: [
    {
      id : 1001,
      value : 'No Food',
      slug : 'No-Food'
    },
    {
      id : 1002,
      value : 'Veg',
      slug : 'Veg'
    },
    {
      id : 1003,
      value : 'Non Veg',
      slug : 'Non-Veg'
    },
    {
      id : 1004,
      value : 'Both',
      slug : 'Both'
    }
  ],

  lead_type: [
    {
      id : 'signup',
      value : 'SignUp'
    },
    {
      id : 'guest',
      value : 'Guest'
    },
    {
      id : 'referred',
      value : 'Referral'
    }
  ],

  leadStatus : [
    {
      id : 'referred',
      value : 'Referred'
    },
    {
      id : 'applied',
      value : 'Applied'
    },
    {
      id : 'approved',
      value : 'Approved'
    },
    {
      id : 'member_registered',
      value : 'Registered'
    },
    {
      id : 'rejected',
      value : 'Rejected'
    }
  ],

  paymentMode: [
    {
      id : 'online',
      value : 'Online'
    },
    {
      id : 'cash',
      value : 'Cash'
    },
    {
      id : 'cheque',
      value : 'Cheque'
    },
    {
      id : 'neft',
      value : 'NEFT'
    },
  ],

  teamType: [
    {
      id : 'global',
      value : 'Global'
    },
    {
      id : 'zone',
      value : 'Zone'
    },
    {
      id : 'chapter',
      value : 'Chapter'
    },
  ],

  event_mode: [
    {
      id: 'virtual',
      value: 'Virtual'
    },
    {
      id: 'physical',
      value: 'Physical'
    },
    {
      id: 'workshop',
      value: 'Workshop'
    },
    {
      id: 'delegation',
      value: 'Delegation'
    },
  ],

  event_day: [
    {
      id:'single', value: 'Single Day'
    },
    {
      id:'multi', value: 'Multi Day'
    }
  ],

  meeting: [
    {
      id:'Chapter', value: 'Chapter'
    },
    {
      id:'Team', value: 'Team'
    }
  ],

  platForm: [
    {
      id:'zoom', value: 'Zoom'
    },
    {
      id:'other', value: 'Other'
    }
  ],

  role_type: [
    {
      id: 'ADMIN', value: 'Admin'
    },
    {
      id: 'ZONE_ADMIN', value: 'Zone Admin'
    },
    {
      id: 'YESCON', value: 'YESCON'
    },
    {
      id: 'CHAPTER_ADMIN', value: 'Chapter Admin'
    },
    {
      id: 'MEMBER', value: 'Member'
    },
    {
      id: 'GUEST', value: 'Guest'
    },
    {
      id: 'VOLUNTEER', value: 'Volunteer'
    }
  ],

  paymentStatus : {
    OPEN : 'open',
    SUCCESS : 'success',
    CANCELLED : 'cancelled',
    FAILED : 'failed'
  },

  activeInactiveStatus : [
    {
      id : 'active',
      value : 'Active'
    },
    {
      id : 'inactive',
      value : 'Inactive'
    }
  ],

  post_type: [
    {
      id: 'BUY', value: "Buy"
    },
    {
      id: 'SELL', value: "Sell"
    }
  ],
  file_reference_object: {
    Members: 'MEMBERS'
  },

  productLimit: 5,

  productImageLimit: 3,

  typeList: [
    {
      id: 'EVENTS', value: 'Event'
    },
    {
      id: 'SPOTLIGHTS', value: 'Spotlight'
    },
    {
      id: 'MEMBER_SUBSCRIPTIONS', value: 'Membership'
    }  ,
    {
      id: 'YESCON_REGISTRATION', value: 'YESCON Registration'
    }   
  ],
  refList: [
    {
      id: 'MEMBERS', value: 'Member'
    },
    {
      id: 'GUEST', value: 'Guest'
    } 
  ],
  payment_status: [
    {
      id: 'open', value: 'Open'
    },
    {
      id: 'success', value: 'Success'
    },
    {
      id: 'cancelled', value: 'Cancelled'
    },
    {
      id: 'failed', value: 'Failed'
    },
    // {
    //   id: 'CREATED', value: 'Created'
    // },
    // {
    //   id: 'SUCCESS', value: 'Success'
    // },
    // {
    //   id: 'FAILED', value: 'Failed'
    // },
    // {
    //   id: 'CANCELLED', value: 'Cancelled'
    // },
    // {
    //   id: 'EXPIRED', value: 'Expired'
    // }
  ],
  payment_mode: [
    {
      id: 'online', value: 'Online'
    },
    {
      id: 'cash', value: 'Cash'
    },
    {
      id: 'cheque', value: 'Cheque'
    },
    {
      id: 'neft', value: 'NEFT',
    },
    {
      id: 'RAZOR_PAY', value: 'Razor Pay'
    }
  ],
  previousYear: [
    {
      id: '2004', value: '2004'
    },
    {
      id: '2005', value: '2005'
    },
    {
      id: '2006', value: '2006'
    },
    {
      id: '2007', value: '2007'
    },
    {
      id: '2008', value: '2008'
    },
    {
      id: '2009', value: '2009'
    },
    {
      id: '2010', value: '2010'
    },
    {
      id: '2011', value: '2011'
    },
    {
      id: '2012', value: '2012'
    },
    {
      id: '2013', value: '2013'
    },
    {
      id: '2014', value: '2014'
    },
    {
      id: '2015', value: '2015'
    },
    {
      id: '2016', value: '2016'
    },
    {
      id: '2017', value: '2017'
    },
    {
      id: '2018', value: '2018'
    },
    {
      id: '2019', value: '2019'
    },
    {
      id: '2020', value: '2020'
    },
    {
      id: '2021', value: '2021'
    },
    {
      id: '2022', value: '2022'
    },
    {
      id: '2023', value: '2023'
    },
    {
      id: '2024', value: '2024'
    }
  ],

  payment_for: [
    {
      id:'event', value: 'Events'
    },
    {
      id:'spotlight', value: 'Spotlight'
    }
  ],

  type: [
    {
      label: 'Guest', value: 'Guest'
    },
    {
      label: 'VIP', value: 'VIP'
    },
    {
      label: 'Visitor', value: 'Visitor'
    },
    {
      label: 'Volunteer', value: 'Volunteer'
    },
    {
      label: 'Invitee', value: 'Invitee'
    }   
  ],

  speaker_type: [
    {
      label: 'Internal', value: 'INTERNAL'
    },
    {
      label: 'External', value: 'EXTERNAL'
    },
    {
      label: 'YESCON', value: 'YESCON'
    }
  ],

  registeredType: [
    {
      label: 'Day 1', value: 'YCD1'
    },
    {
      label: 'Day 2', value: 'YCD2'
    },
    {
      label: 'Both Days', value: 'YC2D'
    },
    {
      label: 'YESMART', value: 'YM'
    }
  ]
};

export { CONSTANTS };