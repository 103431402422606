import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { map, catchError, shareReplay } from 'rxjs/operators';
import { environment } from '../../../../../../environments/environment';
import { LocalStorageService } from '../../services/local-storage.service';
import { NotificationService } from '../../services/notification.service';
import { arrayResponse } from '../../../ag-shared/interfaces/response-interface';
import { CONSTANTS } from 'src/app/ag-module-constants';


@Injectable({
	providedIn: 'root'
})
export class AgAuthService {
	public _PERMISSIONS: string[] = [];
	public isAuth: boolean = false;
	public isPinGenerate: boolean = false;
	public isLocked: boolean = false;
	public isTwoFactorAuthentication: boolean = false;
	BASE_URL: string = environment.API_BASE_URL;
	isOnline: boolean = navigator.onLine;

	constructor(
		private http: HttpClient,
		private storage: LocalStorageService,
		private notification: NotificationService
	) { }

	public login(username: string, password: string): Observable<any> {
		let httpOptions;
		httpOptions = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
				'Access-Control-Allow-Origin': '*',
				'x-ag-date': new Date().toISOString(),
				'x-app-version': environment.appVersion
			})
		};
		const url: string = environment.REST_API_BASE_URL + '/login';
		return this.http.post<any>(url, { username: username, password: password }, httpOptions).pipe(
			map((result: any) => {
				if (result instanceof Array) {
					return result.pop();
				}
				return result;
			}),
			shareReplay(1, 5000),
			catchError(this.handleError)
		);
	}

	public dummyGuestLogin(username: string): Observable<any> {
		let httpOptions;
		httpOptions = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
				'Access-Control-Allow-Origin': '*',
				'x-ag-date': new Date().toISOString(),
				'x-app-version': environment.appVersion
			})
		};
		const url: string = environment.REST_API_BASE_URL + '/guestLogin';
		return this.http.post<any>(url, { username: username }, httpOptions).pipe(
			map((result: any) => {
				if (result instanceof Array) {
					return result.pop();
				}
				return result;
			}),
			shareReplay(1, 5000),
			catchError(this.handleError)
		);
	}

	public useDevice(obj: any): Observable<any> {
		let httpOptions;
		httpOptions = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
				'Access-Control-Allow-Origin': '*',
				'x-ag-date': new Date().toISOString(),
				'x-app-version': environment.appVersion
			})
		};
		const url: string = environment.REST_API_BASE_URL + '/loginUseHere';
		return this.http.post<any>(url, obj, httpOptions).pipe(
			map((result: any) => {
				if (result instanceof Array) {
					return result.pop();
				}
				return result;
			}),
			shareReplay(1, 5000),
			catchError(this.handleError)
		);
	}

	webAuthUserLoginDetails(userId: number) {
		let httpOptions;
		httpOptions = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
				'Access-Control-Allow-Origin': '*',
				'x-ag-date': new Date().toISOString(),
				'x-app-version': environment.appVersion
			})
		};
		const url: string = environment.REST_API_BASE_URL + '/webAuthUserLoginDetails';
		return this.http.post<any>(url, { user_id: userId }, httpOptions).pipe(
			map((result: any) => {
				if (result instanceof Array) {
					return result.pop();
				}
				return result;
			}),
			shareReplay(1, 5000),
			catchError(this.handleError)
		);
	}

	public webAuthn(userId: number) {
		let httpOptions;
		httpOptions = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
				'Access-Control-Allow-Origin': '*',
				'x-ag-date': new Date().toISOString(),
				'x-app-version': environment.appVersion
			})
		};
		const url: string = environment.REST_API_BASE_URL + '/webAuthn';
		return this.http.post<any>(url, { user_id: userId }, httpOptions).pipe(
			map((result: any) => {
				if (result instanceof Array) {
					return result.pop();
				}
				return result;
			}),
			shareReplay(1, 5000),
			catchError(this.handleError)
		);
	}

	public tfa(userId: number, token: number, accountId: number) {
		let httpOptions;
		httpOptions = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
				'Access-Control-Allow-Origin': '*',
				'x-ag-date': new Date().toISOString(),
				'x-app-version': environment.appVersion
			})
		};
		const url: string = environment.REST_API_BASE_URL + '/tfa';
		return this.http.post<any>(url, { user_id: userId, token: token, account_id: accountId }, httpOptions).pipe(
			map((result: any) => {
				if (result instanceof Array) {
					return result.pop();
				}
				return result;
			}),
			shareReplay(1, 5000),
			catchError(this.handleError)
		);
	}

	validateOTP(otp: string, user_id: number): Observable<any> {
		let httpOptions;
		httpOptions = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
				'Access-Control-Allow-Origin': '*',
				'x-ag-date': new Date().toISOString(),
				'x-app-version': environment.appVersion
			})
		};
		const url: string = environment.REST_API_BASE_URL + '/validateOTP';
		return this.http.post<any>(url, { user_id: user_id, otp: otp }, httpOptions).pipe(
			map((result: any) => {
				if (result instanceof Array) {
					return result.pop();
				}
				return result;
			}),
			shareReplay(1, 5000),
			catchError(this.handleError)
		);
	}

	resentOTP(user_id: number, account_id: number, fullname: string, mobilenumber: string): Observable<any> {
		let httpOptions;
		httpOptions = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
				'Access-Control-Allow-Origin': '*',
				'x-ag-date': new Date().toISOString(),
				'x-app-version': environment.appVersion
			})
		};
		const url: string = environment.REST_API_BASE_URL + '/resentOTP';
		return this.http.post<any>(url, { user_id: user_id, account_id: account_id, mobilenumber: mobilenumber, employee_name: fullname }, httpOptions).pipe(
			map((result: any) => {
				if (result instanceof Array) {
					return result.pop();
				}
				return result;
			}),
			shareReplay(1, 5000),
			catchError(this.handleError)
		);
	}

	activeUserLogins(user_id: number, account_id: number): Observable<any> {
		let httpOptions;
		httpOptions = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
				'Access-Control-Allow-Origin': '*',
				'x-ag-date': new Date().toISOString(),
				'x-app-version': environment.appVersion
			})
		};
		const url: string = environment.REST_API_BASE_URL + '/activeUserLogins';
		return this.http.post<any>(url, { user_id: user_id, account_id: account_id }, httpOptions).pipe(
			map((result: any) => {
				if (result instanceof Array) {
					return result.pop();
				}
				return result;
			}),
			shareReplay(1, 5000),
			catchError(this.handleError)
		);
	}

	public validateGoogleRecaptchaV3(token: any): Observable<any> {
		let httpOptions;
		httpOptions = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
				'Access-Control-Allow-Origin': '*',
				'x-ag-date': new Date().toISOString(),
				'x-app-version': environment.appVersion
			})
		};
		const url: string = environment.REST_API_BASE_URL + '/validateGoogleRecaptchaV3';
		return this.http.post<any>(url, { token: token }, httpOptions).pipe(
			map((result: any) => {
				if (result instanceof Array) {
					return result.pop();
				}
				return result;
			}),
			shareReplay(1, 5000),
			catchError(this.handleError)
		);
	}

	public isAuthenticated(): Observable<any> {
		if (localStorage.getItem('authToken')) {
			let expired_time = JSON.parse(localStorage.getItem('expired_time') || '');
			if (expired_time && Date.now() >= expired_time) {
				return of(false);
			}
			let httpOptions = {
				headers: new HttpHeaders({
					'Content-Type': 'application/json',
					'Access-Control-Allow-Origin': '*',
					'x-ag-date': new Date().toISOString(),
					'x-app-version': environment.appVersion,
					'x-auth-token': JSON.parse(localStorage.getItem('authToken') || '')
				})
			};
			const url: string = environment.REST_API_BASE_URL + '/isAuthenticated';
			return this.http.get(url, httpOptions);
		} else {
			return of(false);
		}
	}

	get PERMISSION_DATA() {
		return this._PERMISSIONS;
	}

	// This is the method you want to call at bootstrap
	// Important: It should return a Promise
	loadPermissions(isAuthentication = false): Promise<any> {
		this._PERMISSIONS = [];
		return new Promise((resolve, reject) => {
			if (localStorage.getItem('role_id')) {
				this.isOnline = navigator.onLine;
				if (this.isOnline) {
					if (isAuthentication) {
						this.isAuth = isAuthentication;
						// this.authGetCallMethod('roles/getPermissionsByRole/' + localStorage.role_id)
						// .subscribe(
						// 	(res: any) => {
						// 		if (res.status) {
						// 			this._PERMISSIONS = res.data;
						// 		}
						// 		resolve(true);
						// 	}
						// );
						let roleIds = this.storage.getItem('role_ids');
						let obj = {
							role_ids: roleIds || []
						}
						this.authPostCallMethod(obj, 'roles/getPermissionsByRoleByRoleIds').subscribe(
							(res: arrayResponse) => {
								if (res.status && res.data && res.data.length > 0) {
									this._PERMISSIONS = res.data;
								} else {
									this.notification.showErrorMessage(res.err || res.msg || 'No Permissions Available for this user')
								}
								resolve(true);
							}
						);
					} else {
						this.isAuthenticated().subscribe(
							(auth) => {
								this.isAuth = auth;
								if (auth) {
									// this.authGetCallMethod('roles/getPermissionsByRole/' + localStorage.role_id)
									// .subscribe(
									// 	(res: any) => {
									// 		if (res.status) {
									// 			this._PERMISSIONS = res.data;
									// 		}
									// 		resolve(true);
									// 	}
									// );
									let roleIds = this.storage.getItem('role_ids');
									let obj = {
										role_ids: roleIds || []
									}
									this.authPostCallMethod(obj, 'roles/getPermissionsByRoleByRoleIds').subscribe(
										(res: arrayResponse) => {
											if (res.status && res.data && res.data.length > 0) {
												this._PERMISSIONS = res.data;
											} else {
												this.notification.showErrorMessage(res.err || res.msg || 'No Permissions Available for this user')
											}
											resolve(true);
										}
									);
								} else {
									resolve(false);
								}
							}
						);
					}
				} else {
					this.isAuth = true;
					this._PERMISSIONS = this.storage.getItem('permissions');
					resolve(true);
				}
			} else {
				resolve(false);
			}
		});
	}

	loadVolunteerLoginPermissions(permissions : any = []) : Promise<any> {
		this._PERMISSIONS = []
		return new Promise((resolve, reject) => {
			if(permissions && Array.isArray(permissions) && permissions.length > 0) {
				this._PERMISSIONS = permissions || [];
				resolve(true);
			} else {
				this._PERMISSIONS = [];
				resolve(true);
			}
		});
	};

	getLoginDetailsByReference() {
		let referenceId = this.storage.getItem('reference_id');
		let referenceObject = this.storage.getItem('reference_object');
		let obj = {
			reference_id : referenceId,
			reference_object : referenceObject
		}
		this.authPostCallMethod(obj, 'getLoginDetailsByReference').subscribe(
			(res : arrayResponse) => {
			if(res.status && res.data && res.data.length > 0) {
				let userDetails = res.data[0];
				this.storage.setItem('fullname', userDetails.fullName || '');
				this.storage.setItem('user_business_name', userDetails.user_business_name || '');
				this.storage.setItem('user_unique_code', userDetails.user_unique_code || '');
				this.storage.setItem('user_gst_no', userDetails.user_gst_no || '');
				this.storage.setItem('user_mobilenumber', userDetails.user_mobilenumber || '');
				this.storage.setItem('user_email', userDetails.user_email || '');
				this.storage.setItem('user_fullAddress', userDetails.user_fullAddress || '');
				this.storage.setItem('is_active_member', userDetails.is_active_member || false);
				this.storage.setItem('user_reg_no', userDetails.user_registration_no || '');
			} else {
					if(referenceObject != CONSTANTS.reference_objects.EVENT_VOLUNTEERS) {
						this.storage.setItem('fullname', '');
						this.storage.setItem('user_business_name', '');
						this.storage.setItem('user_unique_code', '');
						this.storage.setItem('user_gst_no', '');
						this.storage.setItem('user_mobilenumber', '');
						this.storage.setItem('user_email', '');
						this.storage.setItem('user_fullAddress', '');
						this.storage.setItem('is_active_member', false);
						this.storage.setItem('user_reg_no', '');
					}
			}
		});
	}

	getLoginDetailsByReferencePromise() {
		return new Promise((resolve, reject) => {
			let referenceId = this.storage.getItem('reference_id');
			let referenceObject = this.storage.getItem('reference_object');
			let obj = {
				reference_id : referenceId,
				reference_object : referenceObject
			}
			this.authPostCallMethod(obj, 'getLoginDetailsByReference').subscribe(
				(res : arrayResponse) => {
					if(res.status && res.data && res.data.length > 0) {
						let userDetails = res.data[0];
						this.storage.setItem('fullname', userDetails.fullName || '');
						this.storage.setItem('user_business_name', userDetails.user_business_name || '');
						this.storage.setItem('user_unique_code', userDetails.user_unique_code || '');
						this.storage.setItem('user_gst_no', userDetails.user_gst_no || '');
						this.storage.setItem('user_mobilenumber', userDetails.user_mobilenumber || '');
						this.storage.setItem('user_email', userDetails.user_email || '');
						this.storage.setItem('user_fullAddress', userDetails.user_fullAddress || '');
						this.storage.setItem('is_active_member', userDetails.is_active_member || false);
						this.storage.setItem('user_reg_no', userDetails.user_registration_no || '');
					} else {
							if(referenceObject != CONSTANTS.reference_objects.EVENT_VOLUNTEERS) {
								this.storage.setItem('fullname', '');
								this.storage.setItem('user_business_name', '');
								this.storage.setItem('user_unique_code', '');
								this.storage.setItem('user_gst_no', '');
								this.storage.setItem('user_mobilenumber', '');
								this.storage.setItem('user_email', '');
								this.storage.setItem('user_fullAddress', '');
								this.storage.setItem('is_active_member', false);
								this.storage.setItem('user_reg_no', '');
							}
					}
					return resolve(true);
				}
			);
		});
	}

	getEventVolunteerDetailsbyMobileNumber() {
		return new Promise((resolve, reject) => {
			this.isAuthenticated().subscribe(
				(auth) => {
					if (auth) {
						let mobilenumber = this.storage.getItem('user_mobilenumber');
						let obj = {
							mobileNumber : mobilenumber
						}
						this.authPostCallMethod(obj, 'getEventVolunteerDetailsbyMobileNumber').subscribe(
							(res : arrayResponse) => {
								if(res.status && res.data && res.data.length > 0) {
									let userDetails = res.data[0];
									let volunteerAccessPermissions = userDetails.volunteer_access_permissions || [];
									let volunteerEventIds = userDetails.volunteer_event_ids || [];
									this.storage.setItem('volunteer_access_permissions', volunteerAccessPermissions);
									this.storage.setItem('volunteer_event_ids', volunteerEventIds);
								} else {
									this.storage.setItem('volunteer_access_permissions', []);
									this.storage.setItem('volunteer_event_ids', []);
								}
								return resolve(true);
							}
						);
					} else {
						this.storage.setItem('volunteer_access_permissions', []);
						this.storage.setItem('volunteer_event_ids', []);
						return resolve(true);
					}
				}
			);
		});
	}

	public authGetCallMethod(serviceCallName: any, isauth = true): Observable<any> {
		let httpOptions;
		if (isauth) {
			httpOptions = {
				headers: new HttpHeaders({
					'Content-Type': 'application/json',
					'Access-Control-Allow-Origin': '*',
					'x-ag-date': new Date().toISOString(),
					'x-app-version': environment.appVersion,
					'x-auth-token': JSON.parse(localStorage.getItem('authToken') || '')
				})
			};
		} else {
			httpOptions = {
				headers: new HttpHeaders({
					'Content-Type': 'application/json',
					'Access-Control-Allow-Origin': '*',
					'x-ag-date': new Date().toISOString(),
					'x-app-version': environment.appVersion
				})
			}
		}
		const url: string = this.BASE_URL + '/' + serviceCallName;
		return this.http.get<any>(url, httpOptions).pipe(
			map((result: any) => {
				if (result instanceof Array) {
					return result.pop();
				}
				return result;
			}),
			shareReplay(1, 5000),
			catchError(this.handleError)
		);
	}

	public authPostCallMethod(obj = {}, serviceCallName = '', isauth = true): Observable<any> {
		let httpOptions;
		if (isauth) {
			httpOptions = {
				headers: new HttpHeaders({
					'Content-Type': 'application/json',
					'Access-Control-Allow-Origin': '*',
					'x-ag-date': new Date().toISOString(),
					'x-app-version': environment.appVersion,
					'x-auth-token': JSON.parse(localStorage.getItem('authToken') || '')
				})
			};
		} else {
			httpOptions = {
				headers: new HttpHeaders({
					'Content-Type': 'application/json',
					'Access-Control-Allow-Origin': '*',
					'x-ag-date': new Date().toISOString(),
					'x-app-version': environment.appVersion
				})
			}
		}
		const url: string = this.BASE_URL + '/' + serviceCallName;
		return this.http.post<any>(url, obj, httpOptions).pipe(
			map((result: any) => {
				if (result instanceof Array) {
					return result.pop();
				}
				return result;
			}),
			shareReplay(1, 5000),
			catchError(this.handleError)
		);
	}

	private handleError(err: HttpErrorResponse): Observable<any> {
		let errorMsg;
		if (err.error instanceof Error) {
			// A client-side or network error occurred. Handle it accordingly.
			errorMsg = `An error occurred: ${err.error.message}`;
		} else {
			// The backend returned an unsuccessful response code.
			// The response body may contain clues as to what went wrong,
			errorMsg = `Backend returned code ${err.status}, body was: ${err.error}`;
		}
		console.error(errorMsg);
		return throwError(new Error(errorMsg));

	}

	hasAccessPermission(permission: string): boolean {
		return this.PERMISSION_DATA.find(data => { return data === permission }) ? true : false;
	}
}